import React from "react";
import ReactDOM from "react-dom";

import { RedocStandalone } from "redoc";


ReactDOM.render(
  <React.StrictMode>
    <RedocStandalone
      specUrl="redoc.yaml"
      options={{
        hideDownloadButton: true,
        nativeScrollbars: true,
        theme: { colors: { primary: { main: "#367ec2" }}},
      }}
    />
  </React.StrictMode>,
  document.getElementById("root")
);
